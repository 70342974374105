import Spinner from "react-bootstrap/Spinner";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
//import Stack from "react-bootstrap/Stack";
//import { Badge } from "react-bootstrap";
import { ModalUploadCall } from "./ModalUploadCall";
import { Link } from "react-router-dom";
import { useQuery } from "@apollo/client";
import DateTime from "./DateTime";
//import { useEffect } from "react";
import { GET_CALLS, GET_FOLLOWED_CALLS } from "../queries/Calls";
import TimeSpan from "./TimeSpan";
import { sendAmplitudeData } from "../services/amplitudeClient";
import { SentimentValueIcon } from "./SentimentValueIcon";
import { useLocalStorage } from "usehooks-ts";

export function CallsTable({
  search,
  startDate,
  endDate,
  filterBookmarked,
  filterSentimentValue,
  filterCallDirection,
  showUploadCallModal,
  setShowUploadCallModal,
  isValidDate
}) {
  const [paginationInput, setPaginationInput] = useLocalStorage("pagination", {
    first: 10,
    after: null,
    last: null,
    before: null,
  });

  const startPage = () => {
    sendAmplitudeData("dashboard-page-start", {});
    setPaginationInput({
      first: 10,
      after: null,
      last: null,
      before: null,
    });
  };

  const nextPage = () => {
    sendAmplitudeData("dashboard-page-next", {});
    setPaginationInput({
      first: 10,
      after: data?.phonecalls.pageInfo.endCursor,
      last: null,
      before: null,
    });
  };

  const prevPage = () => {
    sendAmplitudeData("dashboard-page-previous", {});
    setPaginationInput({
      first: null,
      after: null,
      last: 10,
      before: data?.phonecalls.pageInfo.startCursor,
    });
  };

  const { loading, data, refetch } = useQuery(
    filterBookmarked ? GET_FOLLOWED_CALLS : GET_CALLS,
    {
      variables: {
        ...paginationInput,
        search: search,
        startDate: isValidDate(startDate)
          ? startDate
          : new Date(0).toISOString(),
        endDate: isValidDate(endDate)
          ? endDate
          : new Date(9640000000000).toISOString(),
        filterSentiment:
          filterSentimentValue.length === 0
            ? ["POSITIVE", "NEUTRAL", "NEGATIVE", null]
            : filterSentimentValue,
        filterCallDirection:
          filterCallDirection.length === 0
            ? ["INBOUND", "OUTBOUND"]
            : filterCallDirection,
      },
    },
  );

  return (
    <>
      {loading && (
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      )}
      {!loading && (
        <>
          <Table striped hover borderless className="shadow mb-4">
            <thead className="rounded-top">
              <tr>
                <th></th>
                <th>Caller</th>
                <th>Dialled</th>
                <th>Call Direction</th>
                <th>Duration</th>
                <th>When</th>
                <th>Sentiment</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {data?.phonecalls?.nodes?.map((item) => (
                <tr key={item.id}>
                  <td className="text-center">
                    {item?.follows?.length > 0 && (
                      <i className="bi-bookmark-fill"></i>
                    )}
                  </td>
                  <td>{item.callingAgent || item.callingPartyNumber}</td>
                  <td>{item.dialledAgent || item.dialledPartyNumber}</td>
                  <td>
                    {item.callDirection === "INBOUND" ? (
                      <i className="bi-telephone-inbound" title="Inbound"></i>
                    ) : (
                      <i className="bi-telephone-outbound" title="Outbound"></i>
                    )}
                  </td>
                  <td>
                    <TimeSpan value={item.callDuration} />
                  </td>
                  <td>
                    <DateTime value={item.eventDateTime} />
                  </td>
                  <td>{SentimentValueIcon(item?.sentimentValue)}</td>
                  <td>
                    <ButtonGroup aria-label="Basic example" size="sm">
                      <Link
                        className="btn btn-primary"
                        to={`/call/${item.id}`}
                        state={{
                          data: item,
                          id: item.id,
                        }}
                      >
                        View
                      </Link>
                    </ButtonGroup>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <div className="text-center">
            <ButtonGroup size="sm">
              <Button
                disabled={!data?.phonecalls.pageInfo.hasPreviousPage}
                onClick={startPage}
              >
                Start
              </Button>
              <Button
                disabled={!data?.phonecalls.pageInfo.hasPreviousPage}
                onClick={prevPage}
              >
                Previous
              </Button>
              <Button
                disabled={!data?.phonecalls.pageInfo.hasNextPage}
                onClick={nextPage}
              >
                Next
              </Button>
            </ButtonGroup>
          </div>
          <ModalUploadCall
            show={showUploadCallModal}
            setShow={setShowUploadCallModal}
            reload={refetch}
          />
        </>
      )}
    </>
  );
}
