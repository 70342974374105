import { ApolloClient, InMemoryCache, split, HttpLink } from "@apollo/client";
import { ApolloLink } from "@apollo/client";
import { ApolloProvider } from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";
import { onError } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";
import { getMainDefinition } from "@apollo/client/utilities";
import config from "../config";
import { SSELink } from "./SSELink";
import { RetryLink } from "@apollo/client/link/retry";

// https://www.nextsteps.dev/posts/apollo-client-graphql-and-auth

const AuthorizedApolloProvider = ({ children }) => {
  const { getAccessTokenSilently } = useAuth0();
  const authMiddleware = setContext(async (_, { headers, ...context }) => {
    const token = await getAccessTokenSilently();
    return {
      headers: {
        ...headers,
        ...(token ? { Authorization: `bearer ${token}` } : {}),
      },
      ...context,
    };
  });

  const httpLink = new HttpLink({
    uri: config.GQL_URL + "/graphql",
  });

  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === "OperationDefinition" &&
        definition.operation === "subscription"
      );
    },
    new SSELink({
      url: config.GQL_URL + "/graphql",
      headers: async () => {
        const token = await getAccessTokenSilently();
        return {
          Authorization: `bearer ${token}`,
        };
      },
    }),
    httpLink,
  );

  const client = new ApolloClient({
    link: ApolloLink.from([
      onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors) {
          console.log("GQL Error" + graphQLErrors);
        }
        if (networkError) {
          console.error(`[Network error]:`, networkError);
        }
      }),
      authMiddleware,
      new RetryLink(),
      splitLink,
    ]),
    cache: new InMemoryCache({
      typePolicies: {
        Phonecall: {
          fields: {
            follows: {
              merge(incoming) {
                return incoming;
              },
            },
          },
        },
      },
    }),
    connectToDevTools: false,
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default AuthorizedApolloProvider;
