import React from 'react';
import SpeakerLine from './SpeakerLine';

interface Word {
    text: string;
    speaker: number;
    start: number;
    end: number;
    textConfidence: number;
    speakerConfidence: number;
}

interface HighlightSequence {
    start: number;
    end: number;
}

interface Data {
    words: Word[];
}

const renderWords = (
    data: Data,
    //highlightSequences: HighlightSequence[],
    currentTime: number,
    onSeekTime?: ((time: number) => void) | null
): JSX.Element[] => {
    const lines: JSX.Element[] = [];
    let currentLine: JSX.Element[] = [];
    let currentSpeaker = data.words[0]?.speaker;
    let currentLineStartTime = data.words[0]?.start;

    // Find the word with the closest timestamp to currentTime
    let closestWordIndex = -1;
    let minTimeDiff = Infinity;

    data.words.forEach((word, index) => {
        const avgTime = (word.start + word.end) / 2;
        const timeDiff = Math.abs(avgTime - currentTime);
        if (timeDiff < minTimeDiff) {
            minTimeDiff = timeDiff;
            closestWordIndex = index;
        }
    });

    data.words.forEach((word, index) => {
//        const isHighlighted = highlightSequences.some(
//            (seq) => index >= seq.start && index <= seq.end
//        );

        const isCurrentWord = index === closestWordIndex;

        if (word.speaker !== currentSpeaker && word.speaker !== -1) {
            lines.push(
                <SpeakerLine
                    key={`line-${lines.length}`}
                    speaker={currentSpeaker}
                    timestamp={currentLineStartTime}
                    onTimeClick={onSeekTime}
                >
                    {currentLine}
                </SpeakerLine>
            );

            currentLine = [];
            currentSpeaker = word.speaker;
            currentLineStartTime = word.start;
        }

        currentLine.push(
            <span
                key={index}
                //className={isHighlighted ? 'highlight' : ''}
                style={{
                    //marginRight: '4px',
                    //display: 'inline-block',
                    //wordWrap: 'break-word',
                    textDecoration: isCurrentWord ? 'underline' : undefined,
                    textDecorationThickness: isCurrentWord ? '2px' : undefined,
                    textUnderlineOffset: isCurrentWord ? '4px' : undefined
                }}
            >
                {word.text + " "}
            </span>
        );
    });

    if (currentLine.length > 0) {
        lines.push(
            <SpeakerLine
                key={`line-${lines.length}`}
                speaker={currentSpeaker}
                timestamp={currentLineStartTime}
                onTimeClick={onSeekTime}
            >
                {currentLine}
            </SpeakerLine>
        );
    }

    return lines;
};

interface TranscriptProps {
    data: Data;
    highlightSequences?: HighlightSequence[];
    currentTime: number;
    onSeekTime?: ((time: number) => void) | null;
}

export const Transcript: React.FC<TranscriptProps> = ({
    data,
    currentTime = 0,
    onSeekTime
}) => {
    return (
        <div>
            {renderWords(data, currentTime, onSeekTime)}
        </div>
    );
};