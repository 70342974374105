import { Table } from 'react-bootstrap';
import { GET_USERS } from '../../queries/Users';
import { useQuery } from '@apollo/client';

const UserManagement = () => {
  const { loading, data } = useQuery(
    GET_USERS,
    {
    },
  );

  const formatDateTime = (isoString) => {
    if (!isoString) return 'N/A';
    
    const date = new Date(isoString);
    
    // Format: "Nov 9, 2024 at 12:14 PM"
    return date.toLocaleString('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: '2-digit',
      hour12: true
    }).replace(',', ' at');
  };

  return (
    <div className="container-fluid p-4">
      <div className="row mb-4">
        <div className="col">
          <h2 className="text-2xl font-bold">User Management</h2>
        </div>
      </div>

      <div className="row">
        <div className="col">
          {loading ? (
            <div className="text-center p-4">
              <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          ) : (

              <Table striped hover borderless className="shadow mb-4">
                <thead className="rounded-top">
                  <tr>
                    <th className="border-b p-2">Username</th>
                    <th className="border-b p-2">Last Login</th>
                    <th className="border-b p-2">Account Type</th>
                    <th className="border-b p-2">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.users?.map(user => (
                    <tr key={user.id}>
                      <td className="border-b p-2">{user.email}</td>
                      <td className="border-b p-2">
                        <span title={user.lastLogin}> {/* Show full ISO string on hover */}
                          {formatDateTime(user.lastLoginDateTime)}
                        </span>
                      </td>
                      <td className="border-b p-2">
                        {user.role}
                      </td>
                      <td className="border-b p-2">
                        <button className="btn btn-sm btn-primary me-2 disabled">Edit</button>
                        <button className="btn btn-sm btn-danger disabled">Delete</button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>

          )}
        </div>
      </div>
    </div>
  );
};

export default UserManagement;