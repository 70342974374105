const config = {
  GQL_URL: "https://api.ekkolabs.co",
  WS_URL: "wss://api.ekkolabs.co/graphql",
  AUTH_REDIRECT_URI: "https://app.ekkolabs.co",
  AUTH_LOGOUT_URI: "https://ekkolabs.co",
  AUTH_DOMAIN: 'callshield.uk.auth0.com',
  AUTH_CLIENTID: 'CzLLa5IDHx9bSghr1D3XW0nh6hsntMsW',
  AUTH_AUDIENCE: 'https://api.callshield.ai',
};

export default config;
