import gql from "graphql-tag";

export const GET_CALL = gql`
  query GET_PHONECALL($id: UUID!) {
    phonecall(id: $id) {
      id
      createdDateTime
      createdByUserId
      eventDateTime
      dialledPartyNumber
      callingPartyNumber
      dialledAgent
      callingAgent
      source
      callDirection
      sentiment
      sentimentValue
      status
      transcription
      comments{
        id
        parentCommentId
        createdDateTime
        text
        quotedText
        quotedTextStartWord
        quotedTextEndWord
        user{
          email
          firstName
          lastName
        }
      }
      transcript {
        id
        words {
          text
          speaker
          start
          end
          textConfidence
          speakerConfidence
        }
      }
      keyFacts
      topics
      follows {
        createdByUserId
      }
      sentenceMonitors {
        isMatched
        distance
        monitorTopic {
          id
          label
        }
      }
    }
  }
`;

export const GET_CALLS = gql`
  query GET_PHONECALLS(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $search: String
    $startDate: DateTime
    $endDate: DateTime
    $filterSentiment: [SentimentValue]!
    $filterCallDirection: [CallDirection!]
  ) {
    phonecalls(
      first: $first
      last: $last
      order: { eventDateTime: DESC }
      after: $after
      before: $before
      where: {
        and: [
          { eventDateTime: { gte: $startDate } }
          { eventDateTime: { lte: $endDate } }
          { sentimentValue: { in: $filterSentiment } }
          { callDirection: { in: $filterCallDirection } }
          {
            or: [
              { dialledAgent: { contains: $search } }
              { callingAgent: { contains: $search } }
              { dialledPartyNumber: { contains: $search } }
              { callingPartyNumber: { contains: $search } }
            ]
          }
        ]
      }
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      nodes {
        id
        createdDateTime
        createdByUserId
        eventDateTime
        status
        dialledPartyNumber
        callingPartyNumber
        dialledAgent
        callingAgent
        source
        topics
        sentimentValue
        callDirection
        callDuration
        sentenceMonitors {
          isMatched
          distance
          monitorTopic {
            id
            label
          }
        }
        follows {
          createdByUserId
        }
      }
    }
  }
`;

export const GET_FOLLOWED_CALLS = gql`
  query GET_PHONECALLS(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $search: String
    $startDate: DateTime
    $endDate: DateTime
    $filterSentiment: [SentimentValue]!
    $filterCallDirection: [CallDirection!]
  ) {
    phonecalls(
      first: $first
      last: $last
      order: { eventDateTime: DESC }
      after: $after
      before: $before
      where: {
        and: [
          { follows: { any: true } }
          { eventDateTime: { gte: $startDate } }
          { eventDateTime: { lte: $endDate } }
          { sentimentValue: { in: $filterSentiment } }
          { callDirection: { in: $filterCallDirection } }
          {
            or: [
              { dialledAgent: { contains: $search } }
              { callingAgent: { contains: $search } }
              { dialledPartyNumber: { contains: $search } }
              { callingPartyNumber: { contains: $search } }
            ]
          }
        ]
      }
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      nodes {
        id
        createdDateTime
        createdByUserId
        eventDateTime

        dialledPartyNumber
        callingPartyNumber
        dialledAgent
        callingAgent
        source
        callDirection
        callDuration
        topics
        sentimentValue
        follows {
          createdByUserId
        }
        sentenceMonitors {
          isMatched
          distance
          monitorTopic {
            id
            label
          }
        }
      }
    }
  }
`;

export const GET_USERS = gql`
  query getUsers($startDateTime: DateTime, $endDateTime: DateTime) {
    users {
      id
      callerId
      email
      firstName
      lastName
      phonecallSentimentStatistics(
        startDateTime: $startDateTime
        endDateTime: $endDateTime
      ) {
        positivePhonecalls
        neutralPhonecalls
        negativePhonecalls
        unratedPhonecalls
      }
    }
  }
`;

export const CREATE_PHONECALL_BOOKMARK = gql`
  mutation CREATE_PHONECALL_BOOKMARK($phonecallId: UUID!) {
    createPhonecallBookmark(input: { phonecallId: $phonecallId }) {
      phonecall {
        id
        follows {
          createdByUserId
        }
      }
    }
  }
`;

export const DELETE_PHONECALL_BOOKMARK = gql`
  mutation DELETE_PHONECALL_BOOKMARK($phonecallId: UUID!) {
    deletePhonecallBookmark(input: { phonecallId: $phonecallId }) {
      phonecall {
        id
        follows {
          createdByUserId
        }
      }
    }
  }
`;

export const GET_CONTACT_TYPES = gql`
  query {
    contactTypes {
      id
      name
    }
  }
`;

export const GET_SENTIMENT_TIMESERIES = gql`
  query GET_SENTIMENT_TIMESERIES(
    $startDate: DateTime!
    $endDate: DateTime!
    $groupBy: TimeSeriesGroupBy!
  ) {
    sentimentReportTimeSeries(
      startDate: $startDate
      endDate: $endDate
      groupBy: $groupBy
    ) {
      positiveSentimentPercentage
      neutralSentimentPercentage
      negativeSentimentPercentage
      dateTime
      label
      totalCalls
    }
  }
`;