import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { useAuth } from "../contexts/Auth";
import { useAuth0 } from "@auth0/auth0-react";
import { Link } from "react-router-dom";
import config from "../config";

function Header() {
  const { user, logout } = useAuth();
  const { loginWithRedirect } = useAuth0();

  return (
    <Navbar
      expand="lg"
      className="py-3 border-bottom border-light-subtle"
    >
      <Container fluid={true}>
        <Link className="navbar-brand" to="/">
          <img alt={"logo"} src={'/images/logo-inline.png'} style={{maxHeight: '40px'}}/>
        </Link>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" role="a">
          <Nav className="d-inline-flex mt-2 mt-md-0 ms-md-auto">
            {!user && (
              <>
                <Nav.Item>
                  <Nav.Link
                    as="button"
                    onClick={() =>
                      loginWithRedirect({
                        appState: {
                          redirect_uri: config.AUTH_REDIRECT_URI,
                        },
                      })
                    }
                  >
                    Login
                  </Nav.Link>
                </Nav.Item>
              </>
            )}
            {user && (
              <>
                <NavDropdown
                  id="nav-dropdown"
                  className="dropdown-no-arrow"
                  align="end"
                  title={<i className="bi-person-fill"></i>}
                >
                  <NavDropdown.Item href="" disabled={true}>
                    My Account
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item as="button" onClick={logout}>
                    Logout
                  </NavDropdown.Item>
                </NavDropdown>
              </>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;
