import Breadcrumb from "react-bootstrap/Breadcrumb";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import DateTime from "../../components/DateTime";
import CallContent from "./components/CallContent";
import { NavLink, useParams } from "react-router-dom";
import { useMutation, useQuery, useSubscription } from "@apollo/client";
//import { CREATE_TRANSCRIPTION, DELETE_PHONECALL } from "../../mutations/Cases";
import { AudioPlayer } from "../../components/AudioPlayer";
import {
  CREATE_PHONECALL_BOOKMARK,
  DELETE_PHONECALL_BOOKMARK,
  GET_CALL,
} from "../../queries/Calls";
import { Form } from "react-bootstrap";
import { PHONECALL_UPDATES } from "../../subscriptions/phonecall";
import { sendAmplitudeData } from "../../services/amplitudeClient";
import { useState } from "react";

export const Call = () => {
  //const { state } = useLocation();
  const params = useParams();
  const id = params.id;
  //const navigate = useNavigate();
  const { loading, data, refetch } = useQuery(GET_CALL, {
    variables: { id },
  });

  //const [deletePhonecallFunction] = useMutation(DELETE_PHONECALL);
  //const [createTranscriptionFunction] = useMutation(CREATE_TRANSCRIPTION);
  const [createBookmarkFunction] = useMutation(CREATE_PHONECALL_BOOKMARK);
  const [deleteBookmarkFunction] = useMutation(DELETE_PHONECALL_BOOKMARK);
  const isCheckboxChecked = data?.phonecall.follows.length > 0;
  const [currentTime, setCurrentTime] = useState<number>(0);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [seekTo, setSeekTo] = useState<((time: number) => void) | null>(null);

  useSubscription(PHONECALL_UPDATES);

//  const handleDelete = async (phonecallId) => {
//    sendAmplitudeData("phonecall-delete", { id: phonecallId });
//    await deletePhonecallFunction({
//      variables: {
//        phonecallId,
//      },
//    });
//    navigate(`/dashboard`);
//  };
//
//  const handleCreateTranscription = async (phonecallId) => {
//    sendAmplitudeData("phonecall-transcribe", { id: phonecallId });
//    await createTranscriptionFunction({
//      variables: {
//        id: phonecallId,
//      },
//    });
//  };

  const handleSetBookmarked = async (phonecallId: string, checked: boolean) => {
    if (!checked) {
      sendAmplitudeData("phonecall-bookmark-delete", { id: phonecallId });
      await deleteBookmarkFunction({
        variables: {
          phonecallId: phonecallId,
        },
        update(cache) {
          const normalizedId = cache.identify({
            id,
            __typename: "UserPhonecallFollow",
          });
          cache.evict({ id: normalizedId });
          cache.gc();
        },
      });
      await refetch();
    } else {
      sendAmplitudeData("phonecall-bookmark-create", { id: phonecallId });
      await createBookmarkFunction({
        variables: {
          phonecallId: phonecallId,
        },
      });
      await refetch();
    }
    //setBookmarked(checked);
  };

  const handleMouseUpOnTranscription = () => {
    console.log(`Selected text: ${window.getSelection().toString()}`);
  };

  return (
    <div className="container-fluid">
    <div className="d-flex">
      <div className="py-4 px-3 flex-grow-1">
        <Row className="mb-3">
          <Col xs="12">
            <Breadcrumb>
              <NavLink to="/dashboard" className="breadcrumb-item">
                Dashboard
              </NavLink>
              <Breadcrumb.Item active>Call</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
        {!loading && (
          <div style={{ justifyContent: "center", display: "flex"}}>
          <Row className="mb-3" style={{ maxWidth: "1200px" }}>
            <Col lg="6">
              <dl className="row">
                <dt className="col-sm-3">Caller</dt>
                <dd className="col-sm-9">
                  {data?.phonecall.callingAgent ||
                    data?.phonecall.callingPartyNumber}
                </dd>
                <dt className="col-sm-3">Dialled</dt>
                <dd className="col-sm-9">
                  {data?.phonecall.dialledAgent ||
                    data?.phonecall.dialledPartyNumber}
                </dd>
                <dt className="col-sm-3">When</dt>
                <dd className="col-sm-9">
                  {data ? (
                    <DateTime value={data?.phonecall.eventDateTime} />
                  ) : (
                    "-"
                  )}
                </dd>
                {/*<dt className="col-sm-3">Status</dt>*/}
                {/*<dd className="col-sm-9">*/}
                {/*  <Badge*/}
                {/*    bg={*/}
                {/*      data?.phonecall.status === "COMPLETE"*/}
                {/*        ? "success"*/}
                {/*        : "secondary"*/}
                {/*    }*/}
                {/*    className="text-lowercase rounded-5"*/}
                {/*  >*/}
                {/*    {data?.phonecall.status}*/}
                {/*  </Badge>*/}
                {/*</dd>*/}
                <dt className="col-sm-3">Bookmark</dt>
                <dd className="col-sm-9">
                  <Form.Check
                    type="switch"
                    id="custom-switch"
                    label=""
                    checked={isCheckboxChecked}
                    onChange={(e) =>
                      handleSetBookmarked(data?.phonecall.id, e.target.checked)
                    }
                  />
                </dd>
                {/*<dt className="col-sm-3">Topics</dt>*/}
                {/*<dd className="col-sm-9">*/}
                {/*  <Stack direction="horizontal" gap={2} className="pt-1">*/}
                {/*    {data?.phonecall.topics?.map((topic, index) => (*/}
                {/*      <Badge bg="warning" key={index}>*/}
                {/*        {topic}*/}
                {/*      </Badge>*/}
                {/*      ))}*/}
                {/*  </Stack>*/}
                {/*</dd>*/}
                {/*<dt className="col-sm-3">Actions</dt>*/}
                {/*<dd className="col-sm-9">*/}
                {/*  {data?.phonecall.id && (*/}
                {/*    <ButtonGroup size="sm">*/}
                {/*      <Button*/}
                {/*        variant="primary"*/}
                {/*        disabled={data?.phonecall.transcription}*/}
                {/*        onClick={() =>*/}
                {/*          handleCreateTranscription(data?.phonecall.id)*/}
                {/*        }*/}
                {/*      >*/}
                {/*        Transcribe*/}
                {/*      </Button>*/}
                {/*      <Button*/}
                {/*        variant="danger"*/}
                {/*        onClick={() => handleDelete(data?.phonecall.id)}*/}
                {/*      >*/}
                {/*        Delete*/}
                {/*      </Button>*/}
                {/*    </ButtonGroup>*/}
                {/*  )}*/}
                {/*</dd>*/}
              </dl>
            </Col>
            <Col lg="6" className={"text-end"}></Col>
            <br />
            <Col xs="12" className="mb-4" style={{ minHeight: 60 }}>
              <AudioPlayer
                phonecallId={data?.phonecall.id}
                onTimeUpdate={(time) => setCurrentTime(time)}
                onPlayingStateChange={(playing) => setIsPlaying(playing)}
                onSeekRequest={(seekFn) => setSeekTo(() => seekFn)}
              />
            </Col>
            <CallContent
              data={data}
              handleMouseUpOnTranscription={handleMouseUpOnTranscription}
              currentTime={currentTime}
              isPlaying={isPlaying}
              onSeekTime={seekTo}
            />
          </Row>
          </div>
        )}
      </div>
      {/*<Comments phonecallId={data?.phonecall.id} commentList={data?.phonecall.comments} />*/}
    </div>
    </div>
  );
};
