import { BrowserRouter, Outlet, Route, Routes, Navigate } from "react-router-dom";
import Header from "./components/Header";
import LeftSidebar from "./components/LeftSidebar";
import { AuthProvider } from "./contexts/Auth";
import { Auth0Provider, useAuth0 } from "@auth0/auth0-react";
import Dashboard from "./pages/Dashboard/Dashboard";
import { Case } from "./pages/Case/Case";
import { Call } from "./pages/Call/Call";
import SentimentReport from "./pages/Reports/SentimentReport";
import { initAmplitude } from "./services/amplitudeClient";
import AuthorizedApolloProvider from "./contexts/AuthorizedApolloProvider";
import { Footer } from "./components/Footer";
import config from "./config";
import Users from "./pages/Users/Users";
import { useEffect } from "react";

initAmplitude();

function App() {
  return (
    <BrowserRouter>
      <Auth0Provider
        domain={config.AUTH_DOMAIN}
        clientId={config.AUTH_CLIENTID}
        authorizationParams={{
          redirect_uri: window.location.origin,
          audience: config.AUTH_AUDIENCE,
        }}
      >
        <AuthorizedApolloProvider>
          <AuthProvider>
            <div className="d-flex flex-column min-vh-100">
              <Header />
              <div className="d-flex flex-grow-1">
                <LeftSidebar />
                <div className="container-fluid flex-grow-1">
                    <Routes>
                      <Route path="/" element={<ProtectedRoute />}>
                        <Route path="/" element={<MainLayout />}>
                          {/* Redirect from root "/" to "/dashboard" */}
                          <Route path="/" element={<Navigate to="/dashboard" />} />
                          <Route path="/dashboard" element={<Dashboard />} />
                          <Route path="/case/:id" element={<Case />} />
                          <Route path="/call/:id" element={<Call />} />
                          <Route path="/admin/users" element={<Users />} />
                          <Route path="/report/sentiment" element={<SentimentReport />} />
                        </Route>
                      </Route>
                    </Routes>
                </div>
              </div>

            </div>
          </AuthProvider>
        </AuthorizedApolloProvider>
      </Auth0Provider>
    </BrowserRouter>);
}

function ProtectedRoute() {
  const { isAuthenticated, loginWithRedirect, isLoading } = useAuth0();

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      loginWithRedirect();
    }
  }, [isAuthenticated, isLoading, loginWithRedirect]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!isAuthenticated) {
    return <div>Redirecting to login...</div>;
  }

  return <Outlet />;
}

function MainLayout() {
  return (
    <div className="d-flex flex-column min-vh-100">
      <div className="flex-grow-1">
        <Outlet />
      </div>
      <div className="mt-auto py-5 text-center">
        <Footer />
      </div>
    </div>
    );
}

export default App;
