import Spinner from "react-bootstrap/Spinner";
import Table from "react-bootstrap/Table";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { useQuery } from "@apollo/client";
import { GET_USERS } from "../queries/Calls";

export const UsersTable = ({
  UsersPopoverMenu,
  currentTab,
  startDate,
  endDate,
  isValidDate,
}) => {
  const { loading, data } = useQuery(GET_USERS, {
    variables: {
      startDateTime: isValidDate(startDate)
        ? startDate
        : new Date(0).toISOString(),
      endDateTime: isValidDate(endDate)
        ? endDate
        : new Date(9640000000000).toISOString(),
    },
  });

  return (
    <>
      {loading && (
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      )}
      {!loading && (
        <Table striped hover borderless className="shadow mb-4">
          <thead className="rounded-top">
            <tr>
              <th>User</th>
              <th>Caller ID</th>
              <th>Positive Calls</th>
              <th>Neutral Calls</th>
              <th>Negative Calls</th>
              <th>Unrated Calls</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {data?.users
              ?.filter((user) => user.callerId)
              ?.map(
                ({ firstName, lastName, email, callerId, phonecallSentimentStatistics }, index) => {
                  return (
                    <tr key={index}>
                      <td>{firstName || ""} {lastName || ""}</td>
                      <td>{callerId || ""}</td>
                      <td>{phonecallSentimentStatistics.positivePhonecalls}</td>
                      <td>{phonecallSentimentStatistics.neutralPhonecalls}</td>
                      <td>{phonecallSentimentStatistics.negativePhonecalls}</td>
                      <td>{phonecallSentimentStatistics.unratedPhonecalls}</td>
                      <td>
                        <ButtonGroup aria-label="users-table-actions" size="sm">
                          {
                            <OverlayTrigger
                              trigger="click"
                              placement="top"
                              rootClose
                              overlay={UsersPopoverMenu({ callerId })}
                            >
                              <Button size="sm">
                                <i className="bi bi-menu-button-wide"></i>
                              </Button>
                            </OverlayTrigger>
                          }
                        </ButtonGroup>
                      </td>
                    </tr>
                  );
                },
              )}
          </tbody>
        </Table>
      )}
    </>
  );
};
