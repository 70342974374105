import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Popover from "react-bootstrap/Popover";

import { useLocation, useNavigate } from "react-router-dom";
import { sendAmplitudeData } from "../../services/amplitudeClient";
import FormGroup from "react-bootstrap/FormGroup";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import InputGroup from "react-bootstrap/InputGroup";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Tab from "react-bootstrap/Tab";
import { useSubscription } from "@apollo/client";
import { useEffect, useState } from "react";
import { PHONECALL_UPDATES } from "../../subscriptions/phonecall";
import { useAuth } from "../../contexts/Auth";
import { parseISO, isValid } from "date-fns";
import { UsersTable } from "../../components/UsersTable";
import {
  SentimentValue,
  SentimentValueInfo,
} from "../../components/SentimentValueIcon";
import { CallsTable } from "../../components/CallsTable";
import { useLocalStorage } from "usehooks-ts";

export const TAB_KEYS = {
  users: "#users",
  phonecalls: "#phonecalls",
} as const;

export type TabsKeys = (typeof TAB_KEYS)[keyof typeof TAB_KEYS];

const TAB_KEYS_VALUES = new Set<string>([...Object.values(TAB_KEYS)]);

export default function Dashboard() {
  const navigate = useNavigate();
  const location = useLocation();

  useSubscription(PHONECALL_UPDATES);
  const auth = useAuth();

  const [search, setSearch] = useLocalStorage("search", "");
  const [startDate, setStartDate] = useLocalStorage("startDate", "");
  const [endDate, setEndDate] = useLocalStorage("endDate", "");

  const [currentTab, setCurrentTab] = useState<TabsKeys>(TAB_KEYS.users);

  useEffect(() => {
    const locationHash = location.hash;

    sendAmplitudeData("dashboard", {});

    if (TAB_KEYS_VALUES.has(locationHash)) {
      setCurrentTab(locationHash as TabsKeys);
    } else {
      setCurrentTab("#phonecalls");
    }
  }, [location]);

  const [filterBookmarked, setFilterBookmarked] = useLocalStorage(
    "filterBookmarked",
    false,
  );

  const [filterSentimentValue, setFilterSentimentValue] = useLocalStorage(
    "filterSentimentValue",
    [],
  );

  const [filterCallDirection, setFilterCallDirection] = useLocalStorage(
    "filterCallDirection",
    [],
  );

  const handleSetSearch = (search) => {
    sendAmplitudeData("dashboard-search", { query: search });
    setSearch(search);
  };

  const handleSetStartDate = (date) => {
    if (isValidDate(date))
      sendAmplitudeData("dashboard-date-start", { date: date });
    setStartDate(date.target.value);
  };

  const handleSetEndDate = (date) => {
    if (isValidDate(date))
      sendAmplitudeData("dashboard-date-end", { date: date });
    setEndDate(date.target.value);
  };

  const handleSetFilterBookmarked = (value: boolean) => {
    sendAmplitudeData("dashboard-filter-bookmarked", {
      value,
    });
    setFilterBookmarked(value);
  };

  const handleSetFilterSentimentValue = (value: SentimentValue) => {
    setFilterSentimentValue((prev) => {
      if (prev.includes(value)) {
        return prev.filter((v) => v !== value);
      } else {
        return [...prev, value];
      }
    });
  };

  const [showUploadCallModal, setShowUploadCallModal] = useState(false);

  const allowedUserIds = [
    "auth0|64f9d85a18765af7dd761f33",
    "auth0|64f9d88218765af7dd761f37",
    "auth0|64f9d7823d9e1162dc1173ee",
    "auth0|64f9d76618765af7dd761f22",
    "auth0|64f9d72f18765af7dd761f1f",
    "auth0|64f6ea7cc18541c842c72e46",
  ];

  function isValidDate(dateString) {
    const date = parseISO(dateString);
    return isValid(date);
  }

  const setLocationHash = (newHash: TabsKeys) => {
    navigate(`${location.pathname}${newHash}`);
  };

  const UsersPopoverMenu = ({ callerId }) => {
    if (!callerId) return null;

    let callerIdSentiments = [];

    return (
      <Popover id="users-table-popover-menu">
        <Popover.Body>
          <Form>
            <Form.Label>Select filters for {callerId}</Form.Label>
            {SentimentValueInfo.map(({ value, iconClass }, i) => {
              return (
                <Form.Check
                  type="switch"
                  label={<i className={iconClass}></i>}
                  key={"filter" + value.toLowerCase()}
                  onChange={() => {
                    if (callerIdSentiments.includes(value)) {
                      callerIdSentiments = callerIdSentiments.filter(
                        (v) => v !== value,
                      );
                    } else {
                      callerIdSentiments.push(value);
                    }
                  }}
                ></Form.Check>
              );
            })}
            <Button
              size="sm"
              variant="link"
              className="p-0 d-block"
              onClick={() => {
                setLocationHash(TAB_KEYS.phonecalls);
                setFilterSentimentValue(callerIdSentiments);
                setSearch(callerId);
              }}
            >
              Show In Calls Table
            </Button>
          </Form>
        </Popover.Body>
      </Popover>
    );
  };

  const handleClearFilters = () => {
    setSearch("");
    setStartDate("");
    setEndDate(null);
    setFilterBookmarked(false);
    setFilterSentimentValue([]);
    setFilterCallDirection([]);
    //setCurrentContactType(null); // Add this if you're using contact types
  };

  const handleSetFilterCallDirection = (value: string) => {
    setFilterCallDirection((prev) => {
      if (prev.includes(value)) {
        return [];
      } else {
        return [value];
      }
    });
  };

  return (
    <Container fluid={true}>
      <Row className="my-5 mx-5">
        <Col xs="12" className="mb-4">
          <h2>Dashboard</h2>
        </Col>
        <Col xs="12" className="d-flex align-items-center mb-4 flex-wrap gap-2">
          <ButtonGroup size="sm">
            <OverlayTrigger
              key="tab-users-button"
              overlay={<Tooltip>Show users</Tooltip>}
            >
              <Button
                active={currentTab === TAB_KEYS.users}
                onClick={() => setLocationHash(TAB_KEYS.users)}
              >
                <i className="bi-person"></i>
              </Button>
            </OverlayTrigger>
            <OverlayTrigger
              key="tab-phonecalls-button"
              overlay={<Tooltip>Show phonecalls</Tooltip>}
            >
              <Button
                active={currentTab === TAB_KEYS.phonecalls}
                onClick={() => setLocationHash(TAB_KEYS.phonecalls)}
              >
                <i className="bi-telephone"></i>
              </Button>
            </OverlayTrigger>
          </ButtonGroup>
          <FormGroup style={{ maxWidth: '400px' }}>
            <Form.Control
              value={search}
              size="sm"
              aria-label="case-search-input"
              aria-describedby="case-search-input"
              placeholder="Search name or phone number"
              onChange={(e) => handleSetSearch(e.target.value)}
            />
          </FormGroup>
          <InputGroup size="sm" style={{ maxWidth: '250px' }}>
            <InputGroup.Text id="start-date">Start</InputGroup.Text>
            <Form.Control
              type="date"
              id="dateStart"
              value={startDate == null ? "" : startDate}
              aria-describedby="start-date"
              onChange={(e) => handleSetStartDate(e)}
            />
          </InputGroup>
          <InputGroup size="sm" style={{ maxWidth: '250px' }}>
            <InputGroup.Text id="start-date">End</InputGroup.Text>
            <Form.Control
              type="date"
              id="dateEnd"
              value={endDate == null ? "" : endDate}
              onChange={(e) => handleSetEndDate(e)}
            />
          </InputGroup>
          {currentTab === TAB_KEYS.phonecalls && (
            <div className="ms-auto d-flex gap-2">
              <OverlayTrigger
                key="filter-bookmarked"
                overlay={<Tooltip>{filterBookmarked ? "Hide" : "Show"} Bookmarked</Tooltip>}
              >
                <Button
                  variant="outline-secondary"
                  size="sm"
                  active={filterBookmarked}
                  onClick={() => handleSetFilterBookmarked(!filterBookmarked)}
                >
                  <i className={filterBookmarked ? "bi-bookmark-fill" : "bi-bookmark"}></i>
                </Button>
              </OverlayTrigger>
              <ButtonGroup size="sm" className="me-2">
                {SentimentValueInfo.map(({ value, iconClass }, i) => {
                  const isFiltered = filterSentimentValue.includes(value);

                  return (
                    <OverlayTrigger
                      key={"filter" + value.toLowerCase()}
                      overlay={
                        <Tooltip>
                          {isFiltered ? "Hide" : "Show"} {value?.toLowerCase()}{" "}
                          sentiment
                        </Tooltip>
                      }
                    >
                      <Button
                        variant="outline-secondary"
                        active={isFiltered}
                        onClick={() => handleSetFilterSentimentValue(value)}
                      >
                        <i
                          className={isFiltered ? `${iconClass}-fill` : iconClass}
                        ></i>
                      </Button>
                    </OverlayTrigger>
                  );
                })}
              </ButtonGroup>
              <ButtonGroup size="sm" className="me-2">
                <OverlayTrigger
                  key="filter-inbound-calls"
                  overlay={<Tooltip>Filter inbound calls</Tooltip>}
                >
                  <Button
                    variant="outline-secondary"
                    active={filterCallDirection.includes("INBOUND")}
                    onClick={() => handleSetFilterCallDirection("INBOUND")}
                  >
                    <i className="bi-telephone-inbound"></i>
                  </Button>
                </OverlayTrigger>
                <OverlayTrigger
                  key="filter-outbound-calls"
                  overlay={<Tooltip>Filter outbound calls</Tooltip>}
                >
                  <Button
                    variant="outline-secondary"
                    active={filterCallDirection.includes("OUTBOUND")}
                    onClick={() => handleSetFilterCallDirection("OUTBOUND")}
                  >
                    <i className="bi-telephone-outbound"></i>
                  </Button>
                </OverlayTrigger>
              </ButtonGroup>
              <OverlayTrigger
                key="clear-filters"
                overlay={<Tooltip>Clear filters</Tooltip>}
              >
                <Button
                  variant="outline-secondary"
                  size="sm"
                  className="me-2"
                  onClick={handleClearFilters}
                >
                  <i className="bi-x-square"></i>
                </Button>
              </OverlayTrigger>
              <OverlayTrigger overlay={<Tooltip>Upload Call</Tooltip>}>
                <Button
                  disabled={!allowedUserIds.includes(auth.user?.sub)}
                  onClick={() => setShowUploadCallModal(true)}
                  variant="outline-secondary"
                  size="sm"
                >
                  <i className="bi-plus-lg"></i>
                </Button>
              </OverlayTrigger>
            </div>
          )}
        </Col>
        <Col xs="12">
          <Tab.Container defaultActiveKey={currentTab} activeKey={currentTab}>
            <Tab.Content className="my-5">
              <Tab.Pane eventKey={TAB_KEYS.users}>
                {UsersTable({
                  UsersPopoverMenu,
                  currentTab,
                  startDate,
                  endDate,
                  isValidDate,
                })}
              </Tab.Pane>
              <Tab.Pane eventKey={TAB_KEYS.phonecalls}>
                {CallsTable({
                  search,
                  startDate,
                  endDate,
                  filterBookmarked,
                  filterSentimentValue,
                  filterCallDirection,
                  showUploadCallModal,
                  setShowUploadCallModal,
                  isValidDate
                })}
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </Col>
      </Row>
    </Container>
  );
}
