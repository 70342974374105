import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

export const Footer = () => {
  return (
    <Container as="footer" >
      <Row className="my-5 text-center">
        <Col xs="12">
          <hr className="w-75 mx-auto mb-5 border-dark-subtle" />
          <p className="text-muted">
            Copyright © 2024 Ekko Labs. All rights reserved.
          </p>
          <Link
            to="/"
            className="text-primary text-opacity-50 link-offset-2 link-underline link-underline-opacity-10 pe-none"
            tabIndex={-1}
            aria-disabled="true"
          >
            Terms of Service
          </Link>
          {" · "}
          <Link
            to="/"
            className="text-primary text-opacity-50 link-offset-2 link-underline link-underline-opacity-10 pe-none"
            tabIndex={-1}
            aria-disabled="true"
          >
            Privacy Policy
          </Link>
        </Col>
      </Row>
    </Container>
  );
};
