import Spinner from "react-bootstrap/Spinner";
import {
  AreaChart,
  Area,
  Tooltip,
  Label,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
} from "recharts";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import TooltipB from "react-bootstrap/Tooltip";
import { GET_SENTIMENT_TIMESERIES } from "../../queries/Calls";
import { useLazyQuery } from "@apollo/client";
import InputGroup from "react-bootstrap/InputGroup";
import { useEffect } from "react";
import Form from "react-bootstrap/Form";
import { parseISO, isValid } from "date-fns";
import { ButtonGroup, OverlayTrigger } from "react-bootstrap";
import { useLocalStorage } from "usehooks-ts";

export default function SentimentReport() {
  const [startDate, setStartDate] = useLocalStorage(
    "sentimentReport_startDate",
    "",
  );

  const [endDate, setEndDate] = useLocalStorage("sentimentReport_endDate", "");

  const [groupBy, setGroupBy] = useLocalStorage(
    "sentimentReport_groupBy",
    "MONTH",
  );

  const groupByOptions = [
    { value: "DAY", label: "Day" },
    { value: "WEEK", label: "Week" },
    { value: "MONTH", label: "Month" },
  ];

  const [runQuery, { loading, called, data }] = useLazyQuery(
    GET_SENTIMENT_TIMESERIES,
  );

  useEffect(() => {
    runQuery({
      variables: {
        groupBy: groupBy,
        startDate: startDate || "2023/07/1",
        endDate: endDate || "2023/07/30",
      },
    });
  }, [runQuery, groupBy, startDate, endDate]);

  function isValidDate(dateString) {
    const date = parseISO(dateString);
    return isValid(date);
  }

  const handleSetStartDate = (date) => {
    if (isValidDate(date.target.value)) {
      // sendAmplitudeData("dashboard-date-start", { date: date });
      setStartDate(date.target.value);
    } else {
      //console.log("invalid date: " + date.target.value)
    }
  };

  const handleSetEndDate = (date) => {
    if (isValidDate(date.target.value)) {
      // sendAmplitudeData("dashboard-date-end", { date: date });
      setEndDate(date.target.value);
    }
  };

  function handleSetGroupBy(value: string): void {
    setGroupBy(value);
  }

  return (
    <Container className="my-5">
      <Row>
        <Col xs="12" className="mb-3">
          <h2>Sentiment Report</h2>
        </Col>
        <Col sm="12" lg="6" className="d-flex mb-5">
          <InputGroup size="sm" className="me-2">
            <InputGroup.Text id="start-date">Start</InputGroup.Text>
            <Form.Control // prettier-ignore
              type="date"
              id="dateStart"
              value={startDate == null ? "" : startDate}
              aria-describedby="start-date"
              onChange={(e) => handleSetStartDate(e)}
            />
          </InputGroup>
          <InputGroup size="sm">
            <InputGroup.Text id="start-date">End</InputGroup.Text>
            <Form.Control // prettier-ignore
              type="date"
              id="dateEnd"
              value={endDate == null ? "" : endDate}
              onChange={(e) => handleSetEndDate(e)}
            />
          </InputGroup>

          <ButtonGroup size="sm" className="me-2 ms-2">
            {groupByOptions.map(({ value, label }, i) => {
              const isFiltered = groupBy === value;

              return (
                <OverlayTrigger
                  key={"filter" + value.toLowerCase()}
                  overlay={<TooltipB>Show {label}</TooltipB>}
                >
                  <Button
                    variant="outline-secondary"
                    active={isFiltered}
                    onClick={() => handleSetGroupBy(value)}
                  >
                    {label}
                  </Button>
                </OverlayTrigger>
              );
            })}
          </ButtonGroup>

          <Button
            size="sm"
            className="btn btn-primary"
            onClick={async () => {
              await runQuery({
                variables: {
                  groupBy: groupBy,
                  startDate: startDate || "2023/07/1",
                  endDate: endDate || "2023/07/30",
                },
              });
            }}
          >
            Search
          </Button>
        </Col>
        <Col sm="12">
          {loading && called && (
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading report</span>
            </Spinner>
          )}
          {!loading && called && (
            <ResponsiveContainer width="100%" height="100%" minHeight={500}>
              <AreaChart
                width={500}
                height={400}
                data={data?.sentimentReportTimeSeries || []}
                margin={{
                  top: 10,
                  right: 30,
                  left: 10,
                  bottom: 0,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="label">
                  <Label value="" />
                </XAxis>
                <YAxis
                  yAxisId="left"
                  orientation="left"
                  domain={[0, 100]}
                  ticks={[0, 25, 50, 75, 100]}
                >
                  <Label
                    angle={-90}
                    value="Sentiment %"
                    position="insideLeft"
                  />
                </YAxis>
                <Tooltip />
                <YAxis yAxisId="right" orientation="right">
                  <Label angle={-90} value="Total Calls" position="right" />
                </YAxis>
                <Area
                  yAxisId="left"
                  type="monotone"
                  dataKey="positiveSentimentPercentage"
                  stackId="1"
                  stroke="#8884d8"
                  fill="green"
                />
                <Area
                  yAxisId="left"
                  type="monotone"
                  dataKey="neutralSentimentPercentage"
                  stackId="1"
                  stroke="#82ca9d"
                  fill="yellow"
                />
                <Area
                  yAxisId="left"
                  type="monotone"
                  dataKey="negativeSentimentPercentage"
                  stackId="1"
                  stroke="#ffc658"
                  fill="red"
                />
                <Area
                  yAxisId="right"
                  type="monotone"
                  stackId="2"
                  dataKey="totalCalls"
                  stroke="black"
                  fill="transparent"
                />
              </AreaChart>
            </ResponsiveContainer>
          )}
        </Col>
      </Row>
    </Container>
  );
}
