import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { useNavigate, useMatch } from "react-router-dom";

export const LeftSidebar = () => {
  const navigate = useNavigate();

  return (
    <div
      className="p-3 border-end border-light-subtle flex-shrink-0"
      style={{ width: 270, backgroundColor: '#f2f5fa' }}
    >
      <Navbar>
        <Nav className="d-flex flex-column">
          <Nav.Item>
            <Nav.Link
              className={useMatch("/dashboard") && "active"}
              as="button"
              onClick={() => navigate("dashboard")}
            >
              Dashboard
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              className={useMatch("/report/sentiment") && "active"}
              as="button"
              onClick={() => navigate("/report/sentiment")}
            >
              Reports
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link as="button" onClick={() => navigate("/admin/users")}>
              Users
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link as="button" onClick={() => {}} disabled>
              Settings
            </Nav.Link>
          </Nav.Item>
        </Nav>
      </Navbar>
    </div>
  );
};

export default LeftSidebar;
